// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessory-detail-js": () => import("./../../../src/pages/accessory/detail.js" /* webpackChunkName: "component---src-pages-accessory-detail-js" */),
  "component---src-pages-accessory-index-js": () => import("./../../../src/pages/accessory/index.js" /* webpackChunkName: "component---src-pages-accessory-index-js" */),
  "component---src-pages-agencies-js": () => import("./../../../src/pages/agencies.js" /* webpackChunkName: "component---src-pages-agencies-js" */),
  "component---src-pages-beginner-index-js": () => import("./../../../src/pages/beginner/index.js" /* webpackChunkName: "component---src-pages-beginner-index-js" */),
  "component---src-pages-beginner-knowledge-detail-js": () => import("./../../../src/pages/beginner/knowledge/detail.js" /* webpackChunkName: "component---src-pages-beginner-knowledge-detail-js" */),
  "component---src-pages-beginner-knowledge-index-js": () => import("./../../../src/pages/beginner/knowledge/index.js" /* webpackChunkName: "component---src-pages-beginner-knowledge-index-js" */),
  "component---src-pages-brand-index-js": () => import("./../../../src/pages/brand/index.js" /* webpackChunkName: "component---src-pages-brand-index-js" */),
  "component---src-pages-brand-leda-about-js": () => import("./../../../src/pages/brand/leda/about.js" /* webpackChunkName: "component---src-pages-brand-leda-about-js" */),
  "component---src-pages-brand-leda-detail-js": () => import("./../../../src/pages/brand/leda/detail.js" /* webpackChunkName: "component---src-pages-brand-leda-detail-js" */),
  "component---src-pages-brand-leda-index-js": () => import("./../../../src/pages/brand/leda/index.js" /* webpackChunkName: "component---src-pages-brand-leda-index-js" */),
  "component---src-pages-brand-leda-lineup-js": () => import("./../../../src/pages/brand/leda/lineup.js" /* webpackChunkName: "component---src-pages-brand-leda-lineup-js" */),
  "component---src-pages-brand-morso-about-js": () => import("./../../../src/pages/brand/morso/about.js" /* webpackChunkName: "component---src-pages-brand-morso-about-js" */),
  "component---src-pages-brand-morso-detail-js": () => import("./../../../src/pages/brand/morso/detail.js" /* webpackChunkName: "component---src-pages-brand-morso-detail-js" */),
  "component---src-pages-brand-morso-index-js": () => import("./../../../src/pages/brand/morso/index.js" /* webpackChunkName: "component---src-pages-brand-morso-index-js" */),
  "component---src-pages-brand-morso-lineup-js": () => import("./../../../src/pages/brand/morso/lineup.js" /* webpackChunkName: "component---src-pages-brand-morso-lineup-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-chimney-js": () => import("./../../../src/pages/chimney.js" /* webpackChunkName: "component---src-pages-chimney-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-icons-js": () => import("./../../../src/pages/icons.js" /* webpackChunkName: "component---src-pages-icons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modules-js": () => import("./../../../src/pages/modules.js" /* webpackChunkName: "component---src-pages-modules-js" */),
  "component---src-pages-movie-js": () => import("./../../../src/pages/movie.js" /* webpackChunkName: "component---src-pages-movie-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news/detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reserve-js": () => import("./../../../src/pages/reserve.js" /* webpackChunkName: "component---src-pages-reserve-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */),
  "component---src-pages-sorry-js": () => import("./../../../src/pages/sorry.js" /* webpackChunkName: "component---src-pages-sorry-js" */),
  "component---src-pages-woodstove-js": () => import("./../../../src/pages/woodstove.js" /* webpackChunkName: "component---src-pages-woodstove-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

