/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import './src/styles/app.scss';
import Stickyfill from 'stickyfilljs'

// IntersectionObserver polyfill (Safari, IE)
export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
    }
}

export const onInitialClientRender = () => {
  const elements = document.querySelectorAll('.sticky');
  Stickyfill.add(elements);
}
